teamArr = [];
team1 = [];
team2 =[];

function createTeamArray() {
    let input = document.getElementsByName(`array[]`);

    for (let i = 0; i < input.length; i++) {
        let names = input[i];
        teamArr.push(names.value);
    }
    console.log(teamArr);
    randomTeams();
}

function randomTeams() {
    let currentIndex = teamArr.length;

    while (currentIndex != 0) {
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [teamArr[currentIndex], teamArr[randomIndex]] = [teamArr[randomIndex],teamArr[currentIndex]];
    }
    console.log(teamArr);
    splitTeams();
}

function splitTeams() {
    team1 = teamArr.slice(0, 5);
    team2 = teamArr.slice(5, 10);
    console.log(team1);
    console.log(team2);
    displayTeam1();
    displayTeam2();
}

function displayTeam1() {
    for (let player of team1) {
        const teamList1 = document.getElementById('team1');
        const listItem = document.createElement('li');
        listItem.textContent = player;
        teamList1.appendChild(listItem);
    }
}

function displayTeam2() {
    for (let player of team2) {
        const teamList2 = document.getElementById('team2');
        const listItem = document.createElement('li');
        listItem.textContent = player;
        teamList2.appendChild(listItem);
    }
}
